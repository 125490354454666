<template>
  <v-container fluid>
    <div>
      <student-info :student="Student" />

      <div class="my-5 container">
        <div class="py-5 mt-5 mb-12">
          <v-row>
            <v-col cols="12" md="3">
              <h2 class="primary--text">Interrogation</h2>
            </v-col>

            <v-col cols="12" md="3">
              <h2></h2>
            </v-col>
            <v-col cols="12" md="3">
              <h2></h2>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                @click="addControlMark"
                :disabled="ControlMarks.length > 0"
                class="green white--text rounded"
              >
                Ajouter la moyenne d'interrogation
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col
            class=""
            v-for="(item, i) of ControlMarks"
            :key="i"
            cols="12"
            md="4"
          >
            <div class="rounded elevation-1 py-4 px-2">
              <div class="d-flex justify-space-between">
                <!-- <h3> Interrogation N°{{ i+1 }}</h3> -->
                <h3>Moyenne d'interrogation </h3>
                <span>
                    <v-btn @click="editControlMark(item)" icon>
                        <v-icon >mdi-pencil</v-icon>
                    </v-btn>
                </span>
              </div>
              <p class="mb-1">
                <span>Note :</span>
                <span class="primary--text"> {{ item.score }} / {{item.maxScore}}</span>
              </p>
            
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="my-5 container">
        <div class="py-5 mt-5 mb-12">
          <v-row>
            <v-col cols="12" md="3">
              <h2 class="primary--text">Examen</h2>
            </v-col>

            <v-col cols="12" md="3">
              <h2></h2>
            </v-col>
            <v-col cols="12" md="1">
              <h2></h2>
            </v-col>
            <v-col cols="12" md="5">
              <div class="d-flex ">
                <v-btn
                @click="addExamMark"
                :disabled="ExamMarks.length > 0"
                class="green white--text rounded elevation-0 mr-2"
                rounded 
              >
                Ajouter la note de partiel
              </v-btn>
              <v-btn
                @click="addExamMark"
                :disabled="ExamMarks.length < 1"
                class="orange white--text rounded elevation"
              >
                Ajouter la note de rattrapage
              </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col
            class=""
            v-for="(item, i) of ExamMarks"
            :key="i"
            cols="12"
            md="4"
          >
            <div class="rounded elevation-1 py-4 px-2">
              <div class="d-flex justify-space-between">
                <h3> {{ i==0 ? 'Partiel' :   `Rattrapage N°${i}` }} </h3>
                <span>
                    <v-btn @click="editExamMark(item)" icon>
                        <v-icon >mdi-pencil</v-icon>
                    </v-btn>
                </span>
              </div>
              <p class="mb-1">
                <span>Note :</span>
                <span class="primary--text"> {{ item.score }} / {{item.maxScore}}</span>
              </p>
            
            </div>
          </v-col>
        </v-row>
      </div>

     
    </div>
    <v-dialog v-model="controlDialog" max-width="1000px" class="black">
      <editor-x
        :fields="controlfields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
        :title="
          Model && Model._id
            ? `Modification d'une note d'interrogation`
            : `Ajout d'une note d'interrogation`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
      />
    </v-dialog>
    <v-dialog v-model="examDialog" max-width="1000px" class="black">
      <editor-x
        :fields="examfields"
        :model="Model"
        :callback="callBack"
        :title="
          Model && Model._id
            ? ( `Modification d'une note de partiel` )
            : (ExamMarks.length < 1 ? `Ajout d'une note de Partiel` :   `Ajout d'une note de Rattrapage`)
        "
        @success="successEditor"
      />
    </v-dialog>

    <v-dialog
      v-model="additionelCoastEditorDialog"
      max-width="1000px"
      class="black"
    >
      <editor-x
        :fields="additionelCoastfields"
        :model="Model"
        :callback="callBack"
        
        @success="successEditor"
      />
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "@/utils/data";
import { addMark,updateMark, addAdditionalDebt, deleteFaculty } from "@/api/user";
import { rules } from "@/utils/constant.js";
import EditorX from "@/components/universal/EditorX.vue";
import DeleteDialog from "@/components/universal/DeleteDialog.vue";
import StudentInfo from "../../../../../components/universal/studentInfo.vue";

export default {
  name: "Student",
  components: {
    EditorX,
    DeleteDialog,
    StudentInfo,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    rules,
    headers: [
      {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
  sortable: true,
      },
      {
        text: "Numéro matricule",
        align: "start",
        sortable: true,
        value: "registrationNumber",
      },
      {
        text: "Nom",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      {
        text: "Prénoms",
        align: "start",
        sortable: true,
        value: "firstName",
      },
      {
        text: "Options",
        align: "start",
        sortable: true,
        value: "departmentName",
      },
      {
        text: "Niveau",
        align: "start",
        sortable: true,
        value: "translatedLevel",
      },
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    controlDialog: false,
    examDialog: false,
    additionelCoastEditorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "Student",
      "Schools",
      "UserProfile",
      "StandardFees",
      "AdditionalCoasts",
      "ControlMarks",
      "ExamMarks",
    ]),
    controlfields() {
      return [
        {
          name: "score",
          label: "Note sur 20",
          type: "Number",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon:"mdi-book-open-outline",
          rules:[
                 rules.max(Number(20))
            ]
        },
        {
          name: "modificationJustification",
          label: "Pourquoi voulez-vous modifier la note",
          type: "String",
          col: 12,
          icon:"mdi-help-circle-outline",
          isRequiredForUpdate: true,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
        },

        {
          name: "type",
          label: "",
          type: "Static",
          value: "control",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "student",
          label: "",
          type: "Static",
          value: this.$route.params.id,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "ecue",
          label: "",
          type: "Static",
          value: this.$route.params.ecue,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "level",
          label: "",
          type: "Static",
          value: this.Student.currentLevel,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "schoolYear",
          label: "",
          type: "Static",
          value:
            this.UserProfile.school &&
            this.UserProfile.school.currentYear &&
            this.UserProfile.school.currentYear._id
              ? this.UserProfile.school.currentYear._id
              : this.UserProfile.school.currentYear,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
    examfields() {
      return [
        {
          name: "score",
          label: "Note",
          type: "Number",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules:[
                 rules.max(Number(20))
            ]
        },
        {
          name: "modificationJustification",
          label: "Pourquoi voulez-vous modifier la note",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
        },
        {
          name: "type",
          label: "",
          type: "Static",
          value: "exam",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "student",
          label: "",
          type: "Static",
          value: this.$route.params.id,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "ecue",
          label: "",
          type: "Static",
          value: this.$route.params.ecue,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "level",
          label: "",
          type: "Static",
          value: this.Student.currentLevel,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "schoolYear",
          label: "",
          type: "Static",
          value:
            this.UserProfile.school &&
            this.UserProfile.school.currentYear &&
            this.UserProfile.school.currentYear._id
              ? this.UserProfile.school.currentYear._id
              : this.UserProfile.school.currentYear,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
    additionelCoastfields() {
      return [
        {
          name: "additionalCoast",
          label: "Dette additionel",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getControlMarks,
              params: {},
              getter: this.AdditionalCoasts,
              fieldName: "name",
            },
          },
        },
        {
          name: "student",
          label: "",
          type: "Static",
          value: this.$route.params.id,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "schoolYear",
          label: "",
          type: "Static",
          value:
            this.UserProfile.school &&
            this.UserProfile.school.currentYear &&
            this.UserProfile.school.currentYear._id
              ? this.UserProfile.school.currentYear._id
              : this.UserProfile.school.currentYear,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions(["getStudent", "getUserProfile", "getControlMarks","getExamMarks"]),
    closeForm() {
      this.Model = {};
      this.controlDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getUserProfile();

        await this.getStudent({
          id: this.$route.params.id,
        });

        await this.getControlMarks({
          student: this.$route.params.id,
          ecue: this.$route.params.ecue,
          level: this.Student.currentLevel,
          schoolYear:
            this.UserProfile.school &&
            this.UserProfile.school.currentYear &&
            this.UserProfile.school.currentYear._id
              ? this.UserProfile.school.currentYear._id
              : this.UserProfile.school.currentYear,
        });

        
        await this.getExamMarks({
          student: this.$route.params.id,
          ecue: this.$route.params.ecue,
          level: this.Student.currentLevel,
          schoolYear:
            this.UserProfile.school &&
            this.UserProfile.school.currentYear &&
            this.UserProfile.school.currentYear._id
              ? this.UserProfile.school.currentYear._id
              : this.UserProfile.school.currentYear,
        });


        this.controlDialog = false;
        this.additionelCoastEditorDialog = false;
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les données")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.examDialog= false;
      this.controlDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addControlMark() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addMark;
      this.controlDialog = true;
    },
    editControlMark(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.callBack = updateMark;
      this.controlDialog = true;
    },
    addExamMark() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addMark;
      this.examDialog = true;
    },
    editExamMark(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.callBack = updateMark;
      this.examDialog = true;
    },



    addAdditionalDebt() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addAdditionalDebt;
      this.additionelCoastEditorDialog = true;
    },
    paidAdditionalCoast(item) {
      this.editedIndex = -1;
      this.Model = {
        _id: item._id,
      };
      this.callBack = addAdditionalDebt;
      this.controlDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.controlDialog = true;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteFaculty;
    },
  },
};
</script>
  
  <style >
</style>
  